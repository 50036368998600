import { Component, OnInit, HostListener } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { AppInsightsService } from './framework/service/app-insights.service';
import { BaseUrl } from './framework/constants/url-constants';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RoutePartsService } from './framework/service/route-parts.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  appTitle = BaseUrl.APPTITLE;
  public spinkit = Spinkit;
  pageTitle = '';

  constructor(
    public title: Title,
  private readonly appInsightsService: AppInsightsService,
    private readonly router: Router,
    private readonly routePartsService: RoutePartsService,
    private readonly activeRoute: ActivatedRoute,
  ){

  }
  ngOnInit() {
    this.changePageTitle();
  }
  darkmode() {
    const element = document.body;
    element.classList.toggle('dark-mode');
  }

  @HostListener('keyup',['$event']) keyUpEvent(event: Event) {
    this.appInsightsService.logEvent(""+event.type);
  }
  @HostListener('click',['$event']) clickEvent(event: Event) {
    this.appInsightsService.logEvent(""+event.type);
  }
  @HostListener('dblclick',['$event']) doubleClick(event: Event) {
		this.appInsightsService.logEvent(""+event.type);
  }
  @HostListener('submit',['$event']) submit(event: Event) {
		this.appInsightsService.logEvent(""+event.type);
  }
  @HostListener('drag',['$event']) drag(event: Event) {
		this.appInsightsService.logEvent(""+event.type);
  }
  @HostListener('drop',['$event']) drop(event: Event) {
		this.appInsightsService.logEvent(""+event.type);
  }
  
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      this.appInsightsService.logPageView(window.location.hash.split('/')[1]);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }

      routeParts.reverse();
      this.pageTitle = routeParts
        .map((part) => part.title)
        .reduce((partA, partI) => `${partI}`);
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
